import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/index';

// mensagens de erro
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';
import '../../style/global.css';
import api from '../../services/api';
import getViaCep from '../../services/cep';
import mask from '../../util/mask';
import Button from '../../components/Button/';

function ProfileBusiness() {
  let history = useHistory();

  //States
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [phone, setPhone] = useState('');
  const [whatsApp, setWhatsApp] = useState('');
  const [instagram, setInstagram] = useState('');

  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [localidade, setLocalidade] = useState('');
  const [uf, setUf] = useState('');
  const [numero, setNumero] = useState('');

  // funções
  function handleCancel() {
    history.push('/home');
  }

  const removeCharsPhone = (characteres) => {
    let newPhone = mask.maskPhone(characteres);
    setPhone(newPhone);
  };

  const removeCharsWhatsapp = (characteres) => {
    let newWhatsapp = mask.maskPhone(characteres);
    setWhatsApp(newWhatsapp);
  };

  const makeMaskCEP = (cep) => {
    let newCep = mask.maskCEP(cep);

    setCep(newCep);
  };

  async function handleClickProfile() {
    const token = localStorage.getItem('token');

    let phoneWithoutChars = phone.replace(/[^ \d \s - / ]/g, '');
    let whatsAppWithoutChars = whatsApp.replace(/[^ \d \s - / ]/g, '');

    const endereco = {
      cep,
      logradouro,
      bairro,
      complemento,
      localidade,
      uf,
      numero,
    };

    const data = {
      name,
      whatsApp: whatsAppWithoutChars,
      instagram,
      category,
      phone: phoneWithoutChars,
      endereco,
    };

    try {
      // await api.put('/business', data, {
      //   headers: { 'x-access-token': token },
      // });

      toast.success('Atualizado com sucesso.', {
        className: 'toast-success-PE',
      });
    } catch (error) {
      // alert(error.response.data.message);
      toast.error(error.response.data.message);
    }
  }

  async function handleClickCep() {
    try {
      const response = await getViaCep(cep);

      setCep(response.data.cep);
      setLogradouro(response.data.logradouro);
      setBairro(response.data.bairro);
      setComplemento(response.data.complemento);
      setLocalidade(response.data.localidade);
      setUf(response.data.uf);
    } catch (error) {
      toast.error('Erro ao encontrar CEP, verifique o seu endereço.');
      setLogradouro('');
      setBairro('');
      setNumero('');
      setComplemento('');
      setLocalidade('');
      setUf('');
    }
  }

  function modify(data) {
    setName(data.name);
    setCategory(data.category);
    removeCharsPhone(data.phone);
    removeCharsWhatsapp(data.whatsApp);
    setInstagram(data.instagram);

    if (data.endereco) {
      setCep(data.endereco.cep);
      setLogradouro(data.endereco.logradouro);
      setBairro(data.endereco.bairro);
      setComplemento(data.endereco.complemento);
      setLocalidade(data.endereco.localidade);
      setUf(data.endereco.uf);
      setNumero(data.endereco.numero);
    }
  }

  async function getProfileBusiness() {
    const token = localStorage.getItem('token');

    try {
      // const response = await api.get('/business', {
      //   headers: { 'x-access-token': token },
      // });
      // modify(response.data);
    } catch (error) {
      // alert(error.response.data.message);
      toast.error(error.response.data.message);
    }
  }

  useEffect(() => {
    getProfileBusiness();
  }, []);

  return (
    <section>
      <div className='container '>
        <div className='row justify-content-center '>
          <div className='col-12 col-md-12 col-lg-10 col-xl-10 text-center '>
            <div className='col'>
              <p className='text-title-Appito justify-content-center pt-4 tracking-in-expand '>
                Meu Negócio
              </p>
            </div>
            <div className='row mt-4'>
              <small id='name' className='form-text text-muted'>
                Nome
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito scale-in-center '
                  placeholder='Nome do negocio'
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
            </div>
            <div className='row mt-2'>
              <small id='category' className='form-text text-muted'>
                Categoria
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito scale-in-center '
                  placeholder='Categoria'
                  value={category}
                  onChange={(event) => setCategory(event.target.value)}
                />
              </div>
            </div>
            <div className='col'>
              <p className='text-subTitle-Appito justify-content-center pt-4 tracking-in-expand '>
                Contatos
              </p>
            </div>
            <div className='row'>
              <small id='Telefone' className='form-text text-muted'>
                Telefone fixo
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control mb-1 input-appito  scale-in-center'
                  placeholder='Telefone fixo'
                  value={phone}
                  onChange={(event) => removeCharsPhone(event.target.value)}
                />
              </div>
            </div>
            <div className='row mt-2'>
              <small id='Whatsapp' className='form-text text-muted'>
                Whatsapp
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control mb-1 input-appito scale-in-center '
                  placeholder='Whatsapp'
                  value={whatsApp}
                  onChange={(event) => removeCharsWhatsapp(event.target.value)}
                />
              </div>
            </div>
            <div className='row mt-2'>
              <small id='Whatsapp' className='form-text text-muted'>
                Instagram(opcional)
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control mb-1 input-appito scale-in-center '
                  placeholder='Digite seu @'
                  value={instagram}
                  onChange={(event) => setInstagram(event.target.value)}
                />
              </div>
            </div>

            {/* inicio endereco */}
            <div className=' mt-4 divisor' />
            <div className='col'>
              <p className='text-subTitle-Appito justify-content-center pt-4 tracking-in-expand '>
                Endereço
              </p>
            </div>
            <div className='row mt-4'>
              <div className='d-flex align-items-center'>
                <input
                  type='text'
                  className='form-control  input-appito col scale-in-center'
                  placeholder='CEP'
                  value={cep}
                  onChange={(event) => makeMaskCEP(event.target.value)}
                />
                <button
                  className='btn button-secundary-appito ms-4 col-4 scale-in-center '
                  type='button'
                  onClick={() => handleClickCep()}
                >
                  Buscar
                </button>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col'>
                <input
                  type='text'
                  className='form-control mb-1 input-appito scale-in-center '
                  placeholder='Logradouro'
                  value={logradouro}
                  onChange={(event) => setLogradouro(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col'>
                <input
                  type='text'
                  className='form-control mb-1 input-appito scale-in-center'
                  placeholder='Bairro'
                  value={bairro}
                  onChange={(event) => setBairro(event.target.value)}
                />
              </div>

              <div className='col-4'>
                <input
                  type='text'
                  className='form-control mb-1 input-appito scale-in-center '
                  placeholder='Numero'
                  value={numero}
                  onChange={(event) => setNumero(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col'>
                <input
                  type='text'
                  className='form-control mb-1 input-appito scale-in-center '
                  placeholder='Complemento(opcional)'
                  value={complemento}
                  onChange={(event) => setComplemento(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col'>
                <input
                  type='text'
                  className='form-control mb-1 input-appito scale-in-center '
                  placeholder='Localidade'
                  value={localidade}
                  onChange={(event) => setLocalidade(event.target.value)}
                />
              </div>

              <div className='col-4'>
                <input
                  type='text'
                  className='form-control mb-1 input-appito scale-in-center '
                  placeholder='UF'
                  value={uf}
                  onChange={(event) => setUf(event.target.value)}
                />
              </div>
            </div>
            <div className=' mt-2 divisor' />
            {/* fim endereco */}
            <div>
              <Button
                text='Atualizar'
                typeButton='primary'
                onClick={() => handleClickProfile()}
              />
            </div>

            <div className=' mb-2 mt-2 divisor' />
            <div>
              <Button
                text='Voltar'
                typeButton='secundary'
                onClick={() => handleCancel()}
                style='btn-sm col-3 mb-4 scale-in-center'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfileBusiness;
