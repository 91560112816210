import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// mensagens de erro
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import '../../style/global.css';

import api from '../../services/api';
import Card from '../../components/Card';

import Button from '../../components/Button/';

import Header from '../../components/Header/index';

import { accessValidator } from '../../util/validationProfile';

function Plan() {
  const [allQtdProducts, setAllAtdProducts] = useState(0);
  const [allQtdSales, setAllAtdSales] = useState(0);
  const [business, setBusiness] = useState({});
  //States

  let history = useHistory();

  function handleClickSales() {
    history.push('/sales');
  }

  const handleClickClipboard = async () => {
    if (business.name) {
      const businessName = business.name;

      let clipboard =
        window.origin + '/mostruario/' + businessName.replace(/ /g, '.');
      navigator.clipboard.writeText(clipboard);
      toast.success('Link do mostruário copiado com sucesso ');
    }
  };

  // const getBusiness = async () => {
  //   const token = localStorage.getItem('token');

  //   try {
  //     const response = await api.get('/businesses', {
  //       headers: { 'x-access-token': token },
  //     });

  //     setBusiness(response.data);
  //   } catch (error) {
  //     toast.error(error.response.data.message);
  //   }
  // };

  const titleProduct = 'Starter BETA';
  const titleSales = ' Grátis';

  const handleCancel = () => {
    history.push('/home');
  };

  const validate = () => {
    const verify = accessValidator('plan');

    if(verify === false) {
      toast.info("Seu usuário não tem permissões para esse sistema, contate o suporte")
      history.push('/home');
    }
  }

  useEffect(() => {
    validate();
  }, []);

  return (
    <>
      <section>
        <div className='container '>
          <div className='row justify-content-center '>
            <div className='col-12 col-md-12 col-lg-10 col-xl-10 '>
              <div className='col'>
                <p className='text-subTitle-Appito justify-content-center pt-2 '>
                  <b>Encontre o plano perfeito para você</b>
                </p>
              </div>

              <div className='card  cardPlan mt-4 scale-in-center'>
                <div className='card-body'>
                  <h5 className='card-title'>
                    <b> Grátis</b>
                  </h5>
                  <h6 className='card-subtitle mb-2 text-muted'>
                    Plano básico - BETA
                  </h6>
                  <p className='card-text'>&bull; Até 1 usuário;</p>
                  <p className='card-text'>&bull; Gerar pedidos;</p>
                  <p className='card-text'>&bull; Cadastro de produtos;</p>
                  <p className='card-text'>
                    &bull; Relatório de Lucro por venda;
                  </p>
                  <p className='card-text'>
                    &bull; Relatório de Lucro por busca;
                  </p>
                  <p className='card-text'>
                    &bull; Link de mostruário de produtos para clientes
                  </p>
                  <h3> PREÇO: 0,00</h3>
                </div>
              </div>

              <div className='card  cardPlan mt-4 mb-4 scale-in-center'>
                <div className='card-body'>
                  <h5 className='card-title'>
                    <b> Starter</b>
                  </h5>
                  <h6 className='card-subtitle mb-2 text-muted'>
                    Plano Starter - BETA
                  </h6>
                  <p className='card-text'>&bull; Até 3 usuários</p>
                  <p className='card-text'>&bull; Gerar pedidos</p>
                  <p className='card-text'>
                    &bull; Cadastro de produtos com imagens
                  </p>
                  <p className='card-text'>
                    &bull; Relatório de Lucro por venda
                  </p>
                  <p className='card-text'>
                    &bull; Relatório de Lucro por busca
                  </p>
                  <p className='card-text'>
                    &bull; Link de mostruário de produtos para clientes
                  </p>
                  <h3>
                    PREÇO: 60,00
                    <small>
                      <b>válido por 3 meses</b>
                    </small>
                  </h3>
                </div>
              </div>

              <div className=' mb-2 mt-4 divisor-green-1' />

              <div className='text-center '>
                <Button
                  text='Voltar'
                  typeButton='secundary'
                  onClick={() => handleCancel()}
                  style='btn-sm col-3 mb-4'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Plan;

// style={{ backgroundColor: '#fe3ffc72' }}
